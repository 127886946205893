@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flex-grow {
  flex: 1 0 auto;
}